import styles from "./KayitFormuMobil.module.css";

const KayitFormuMobil = () => {
  return (
    <div className={styles.kayitFormuMobil}>
      <div className={styles.kayitFormuMobilChild} />
      <img
        className={styles.kayitFormuMobilItem}
        alt=""
        src="/group-13626.svg"
      />
      <div className={styles.kayitFormu}>KAYIT FORMU</div>
      <div className={styles.kaydolWrapper}>
        <div className={styles.kaydol}>KAYDOL</div>
      </div>
      <div className={styles.adParent}>
        <div className={styles.ad}>Ad</div>
        <div className={styles.ifreBelirle}>Şifre Belirle</div>
        <div className={styles.ePostaAdresi}>E-Posta Adresi</div>
        <div className={styles.doumTarihi}>Doğum Tarihi</div>
        <div className={styles.cinsiyet}>Cinsiyet</div>
        <div className={styles.soyad}>Soyad</div>
        <div className={styles.ifreniTekrarYaz}>Şifreni Tekrar Yaz</div>
        <div className={styles.telefonNumaras}>Telefon Numarası</div>
        <div className={styles.groupChild} />
        <div className={styles.groupItem} />
        <div className={styles.groupInner} />
        <div className={styles.rectangleDiv} />
        <div className={styles.groupChild1} />
        <div className={styles.groupChild2} />
        <div className={styles.groupChild3} />
        <div className={styles.groupChild4} />
        <div className={styles.groupChild5} />
        <div className={styles.groupChild6} />
        <img className={styles.path14199Icon} alt="" src="/path-14199.svg" />
        <img className={styles.path14198Icon} alt="" src="/path-14199.svg" />
        <img className={styles.path14196Icon} alt="" src="/path-14199.svg" />
        <img className={styles.path14197Icon} alt="" src="/path-14199.svg" />
      </div>
    </div>
  );
};

export default KayitFormuMobil;
