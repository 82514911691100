import { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./GirisMobil.module.css";

const GirisMobil = () => {
  const navigate = useNavigate();
  useEffect(() => {
    const scrollAnimElements = document.querySelectorAll(
      "[data-animate-on-scroll]"
    );
    const observer = new IntersectionObserver(
      (entries) => {
        for (const entry of entries) {
          if (entry.isIntersecting || entry.intersectionRatio > 0) {
            const targetElement = entry.target;
            targetElement.classList.add(styles.animate);
            observer.unobserve(targetElement);
          }
        }
      },
      {
        threshold: 0.15,
      }
    );

    for (let i = 0; i < scrollAnimElements.length; i++) {
      observer.observe(scrollAnimElements[i]);
    }

    return () => {
      for (let i = 0; i < scrollAnimElements.length; i++) {
        observer.unobserve(scrollAnimElements[i]);
      }
    };
  }, []);

  const onRectangleButtonClick = useCallback(() => {
    navigate("/ana-panel-mobil");
  }, [navigate]);

  const onGRYAPClick = useCallback(() => {
    navigate("/ana-panel-mobil");
  }, [navigate]);

  return (
    <div className={styles.girisMobil}>
      <div className={styles.girisMobilChild} />
      <img
        className={styles.girisMobilItem}
        alt=""
        src="/group-13626.svg"
        data-animate-on-scroll
      />
      <div className={styles.kullancGiriiParent} data-animate-on-scroll>
        <div className={styles.kullancGirii}>Kullanıcı Girişi</div>
        <div className={styles.birHesabnVar}>Bir Hesabın var mı;?</div>
        <div className={styles.beniHatrla}>Beni Hatırla</div>
        <div className={styles.ifremiUnuttum}>Şifremi Unuttum</div>
        <div className={styles.groupChild} />
        <button
          className={styles.groupItem}
          onClick={onRectangleButtonClick}
          data-animate-on-scroll
        />
        <div className={styles.groupInner} />
        <div className={styles.kullancAd}>Kullanıcı Adı</div>
        <img className={styles.groupIcon} alt="" src="/group-1943.svg" />
        <div className={styles.rectangleDiv} />
        <a className={styles.grYap} onClick={onGRYAPClick}>
          GİRİŞ YAP
        </a>
        <a className={styles.kaydolWrapper}>
          <div className={styles.kaydol}>Kaydol</div>
        </a>
        <div className={styles.path3514Parent}>
          <img className={styles.path3514Icon} alt="" src="/path-3514.svg" />
          <img className={styles.path3515Icon} alt="" src="/path-3515.svg" />
          <img className={styles.groupChild1} alt="" src="/group-1946.svg" />
          <div className={styles.groupChild2} />
          <div className={styles.groupChild2} />
          <div className={styles.facebookIleGiri}>Facebook ile Giriş Yap</div>
        </div>
        <div className={styles.path3514Group}>
          <img className={styles.path3514Icon} alt="" src="/path-35141.svg" />
          <img className={styles.groupChild1} alt="" src="/group-1946.svg" />
          <div className={styles.groupChild2} />
          <div className={styles.groupChild2} />
          <div className={styles.googleIleGiri}>Google ile Giriş Yap</div>
          <img className={styles.groupChild7} alt="" src="/group-7650.svg" />
        </div>
        <img className={styles.groupChild8} alt="" src="/group-1949.svg" />
        <img className={styles.groupChild9} alt="" src="/group-1951.svg" />
        <img className={styles.groupChild10} alt="" src="/group-1953.svg" />
      </div>
      <img
        className={styles.fhjbW567210823Converted0Icon}
        alt=""
        src="/fhjb-w567-210823-converted03@2x.png"
        data-animate-on-scroll
      />
      <img
        className={styles.fhjbW567210823Converted0Icon1}
        alt=""
        src="/fhjb-w567-210823-converted02@2x.png"
        data-animate-on-scroll
      />
      <img
        className={styles.girisMobilInner}
        alt=""
        src="/group-13628@2x.png"
      />
    </div>
  );
};

export default GirisMobil;
