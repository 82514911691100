import { useCallback, useEffect } from "react";
import "antd/dist/antd.min.css";
import { Collapse } from "antd";
import { useNavigate } from "react-router-dom";
import styles from "./AnaPanelMobil.module.css";

const AnaPanelMobil = () => {
  const navigate = useNavigate();
  useEffect(() => {
    const scrollAnimElements = document.querySelectorAll(
      "[data-animate-on-scroll]"
    );
    const observer = new IntersectionObserver(
      (entries) => {
        for (const entry of entries) {
          if (entry.isIntersecting || entry.intersectionRatio > 0) {
            const targetElement = entry.target;
            targetElement.classList.add(styles.animate);
            observer.unobserve(targetElement);
          }
        }
      },
      {
        threshold: 0.15,
      }
    );

    for (let i = 0; i < scrollAnimElements.length; i++) {
      observer.observe(scrollAnimElements[i]);
    }

    return () => {
      for (let i = 0; i < scrollAnimElements.length; i++) {
        observer.unobserve(scrollAnimElements[i]);
      }
    };
  }, []);

  const onProfilClick = useCallback(() => {
    navigate("/profil-mobil");
  }, [navigate]);

  return (
    <div className={styles.anaPanelMobil}>
      <div className={styles.hedeflerimWrapper} data-animate-on-scroll>
        <div className={styles.hedeflerim}>Hedeflerim</div>
      </div>
      <div className={styles.sonOkunanKitapWrapper} data-animate-on-scroll>
        <div className={styles.sonOkunanKitap}>Son Okunan Kitap</div>
      </div>
      <div className={styles.favoriKitaplarmWrapper} data-animate-on-scroll>
        <div className={styles.favoriKitaplarm}>Favori Kitaplarım</div>
      </div>
      <div className={styles.etkinlikVeDuyurularWrapper} data-animate-on-scroll>
        <div className={styles.etkinlikVeDuyurular}>Etkinlik ve Duyurular</div>
      </div>
      <div className={styles.enBeenilenlerWrapper} data-animate-on-scroll>
        <div className={styles.enBeenilenler}>En Beğenilenler</div>
      </div>
      <div className={styles.sorumluluklarmWrapper} data-animate-on-scroll>
        <div className={styles.sorumluluklarm}>Sorumluluklarım</div>
      </div>
      <div className={styles.yeniEklenenlerWrapper} data-animate-on-scroll>
        <div className={styles.enBeenilenler}>Yeni Eklenenler</div>
      </div>
      <div className={styles.okOkunanlarWrapper} data-animate-on-scroll>
        <div className={styles.okOkunanlar}>Çok Okunanlar</div>
      </div>
      <div className={styles.kaybolanUakParent} data-animate-on-scroll>
        <div className={styles.kaybolanUak}>
          <p className={styles.kaybolan}>Kaybolan</p>
          <p className={styles.kaybolan}>Uçak</p>
        </div>
        <div className={styles.sayfa}>12 Sayfa</div>
        <div className={styles.seviye}>1.Seviye</div>
        <div className={styles.okundu}>%35 okundu</div>
      </div>
      <div className={styles.okumayaDevamEtWrapper} data-animate-on-scroll>
        <div className={styles.okumayaDevamEt}>Okumaya Devam Et</div>
      </div>
      <img
        className={styles.anaPanelMobilChild}
        alt=""
        src="/group-19718@2x.png"
        data-animate-on-scroll
      />
      <img className={styles.anaPanelMobilItem} alt="" src="/group-13641.svg" />
      <div className={styles.proflZet} data-animate-on-scroll>
        PROFİL ÖZETİ
      </div>
      <div className={styles.groupParent}>
        <div className={styles.okuduumKitaplarParent} data-animate-on-scroll>
          <div className={styles.okuduumKitaplar}>
            <p className={styles.kaybolan}>Okuduğum</p>
            <p className={styles.kaybolan}>Kitaplar</p>
          </div>
          <div className={styles.div}>20</div>
          <img className={styles.groupChild} alt="" src="/group-13651.svg" />
        </div>
        <div className={styles.parent} data-animate-on-scroll>
          <div className={styles.div1}>45</div>
          <div className={styles.tamamlananSorumluluklar}>
            <p className={styles.kaybolan}>Tamamlanan</p>
            <p className={styles.kaybolan}>Sorumluluklar</p>
          </div>
          <img className={styles.groupItem} alt="" src="/group-13649.svg" />
        </div>
        <div className={styles.group} data-animate-on-scroll>
          <div className={styles.div2}>5500</div>
          <div className={styles.topladmPuan}>
            <p className={styles.kaybolan}>Topladığım</p>
            <p className={styles.kaybolan}>Puan</p>
          </div>
          <img className={styles.groupInner} alt="" src="/group-13648@2x.png" />
        </div>
        <div className={styles.dinlediimKitaplarParent} data-animate-on-scroll>
          <div className={styles.dinlediimKitaplar}>
            <p className={styles.kaybolan}>Dinlediğim</p>
            <p className={styles.kaybolan}>Kitaplar</p>
          </div>
          <div className={styles.div}>15</div>
          <img className={styles.groupIcon} alt="" src="/group-13650.svg" />
        </div>
      </div>
      <div className={styles.gnlkParent}>
        <div className={styles.gnlk}>Günlük</div>
        <div className={styles.div4}>80</div>
        <div className={styles.div5}>7</div>
        <div className={styles.div6}>1</div>
        <div className={styles.div7}>630</div>
        <div className={styles.div8}>35</div>
        <div className={styles.div9}>7</div>
        <div className={styles.div10}>3500</div>
        <div className={styles.div11}>758</div>
        <div className={styles.div12}>365</div>
        <div className={styles.kelimeSays}>
          <p className={styles.kaybolan}>Kelime</p>
          <p className={styles.kaybolan}>Sayısı</p>
        </div>
        <div className={styles.sayfaSays}>
          <p className={styles.kaybolan}>Sayfa</p>
          <p className={styles.kaybolan}>Sayısı</p>
        </div>
        <div className={styles.kitapSays}>
          <p className={styles.kaybolan}>Kitap</p>
          <p className={styles.kaybolan}>Sayısı</p>
        </div>
        <div className={styles.haftalk}>Haftalık</div>
        <div className={styles.aylk}>Aylık</div>
        <div className={styles.lineDiv} />
        <div className={styles.groupChild1} />
        <div className={styles.groupChild2} />
        <div className={styles.groupChild3} />
      </div>
      <div className={styles.anaPanelMobilInner} />
      <div className={styles.rectangleDiv} />
      <div className={styles.anaPanelMobilChild1} />
      <div className={styles.anaPanelMobilChild2} />
      <div className={styles.anaPanelMobilChild3} />
      <div className={styles.ekimCumhuriyetBayramKitapContainer}>
        <p className={styles.kaybolan}>29 Ekim Cumhuriyet Bayramı</p>
        <p className={styles.kaybolan}>Kitap Okuma Etkinliği</p>
      </div>
      <div className={styles.ekimCumhuriyetBayramKitapContainer1}>
        <p className={styles.kaybolan}>29 Ekim Cumhuriyet Bayramı</p>
        <p className={styles.kaybolan}>Kitap Okuma Etkinliği</p>
      </div>
      <div className={styles.ekimCumhuriyetBayramKitapContainer2}>
        <p className={styles.kaybolan}>29 Ekim Cumhuriyet Bayramı</p>
        <p className={styles.kaybolan}>Kitap Okuma Etkinliği</p>
      </div>
      <div className={styles.ekimCumhuriyetBayramKitapContainer3}>
        <p className={styles.kaybolan}>29 Ekim Cumhuriyet Bayramı</p>
        <p className={styles.kaybolan}>Kitap Okuma Etkinliği</p>
      </div>
      <div className={styles.groupDiv}>
        <img className={styles.groupChild4} alt="" />
      </div>
      <img
        className={styles.anaPanelMobilChild4}
        alt=""
        src="/group-13687@2x.png"
      />
      <img
        className={styles.anaPanelMobilChild5}
        alt=""
        src="/group-13687@2x.png"
      />
      <img
        className={styles.anaPanelMobilChild6}
        alt=""
        src="/group-13687@2x.png"
      />
      <div
        className={styles.loremIpsumDolor}
      >{`Lorem ipsum dolor sit amet, consectetur adipiscing elit. In auctor mi ut risus maximus volutpat. Aenean dapibus molestie diam id feugiat. Praesent cursus ex mi, vitae porttitor nulla venenatis nec. Curabitur eu nisi at dui hendrerit mattis. Pellentesque placerat sed dui congue porta. Aenean sit amet tristique turpis. Nulla ultricies nec ipsum ac aliquam. `}</div>
      <div className={styles.scrollGroup9}>
        <img
          className={styles.scrollGroup9Child}
          alt=""
          src="/group-18894@2x.png"
        />
        <img
          className={styles.scrollGroup9Item}
          alt=""
          src="/group-18893@2x.png"
        />
        <img
          className={styles.scrollGroup9Inner}
          alt=""
          src="/group-18895@2x.png"
        />
        <img
          className={styles.scrollGroup9Child1}
          alt=""
          src="/group-18897@2x.png"
        />
        <img
          className={styles.scrollGroup9Child2}
          alt=""
          src="/group-18894@2x.png"
        />
        <img
          className={styles.scrollGroup9Child3}
          alt=""
          src="/group-18896@2x.png"
        />
      </div>
      <div className={styles.scrollGroup1}>
        <img
          className={styles.scrollGroup1Child}
          alt=""
          src="/group-18890@2x.png"
        />
        <img
          className={styles.scrollGroup1Item}
          alt=""
          src="/group-18891@2x.png"
        />
        <img
          className={styles.scrollGroup1Inner}
          alt=""
          src="/group-18892@2x.png"
        />
      </div>
      <div className={styles.mehmetAliSrmeliParent} data-animate-on-scroll>
        <div className={styles.mehmetAliSrmeli}>Mehmet Ali Sürmeli</div>
        <div className={styles.snf}>3.Sınıf</div>
        <div className={styles.groupChild5} />
        <img className={styles.groupChild6} alt="" src="/group-34.svg" />
      </div>
      <div className={styles.hoGeldin} data-animate-on-scroll>
        Hoş Geldin
      </div>
      <img
        className={styles.anaPanelMobilChild7}
        alt=""
        src="/group-18512.svg"
        data-animate-on-scroll
      />
      <footer className={styles.groupFooter} id="menu-bar">
        <section className={styles.path18812Parent} id="menu-bar">
          <img className={styles.path18812Icon} alt="" src="/path-18812.svg" />
          <img className={styles.path18813Icon} alt="" src="/path-18813.svg" />
          <div className={styles.groupContainer}>
            <img className={styles.groupChild7} alt="" src="/group-71.svg" />
            <img className={styles.profilChild} alt="" src="/group-81@2x.png" />
          </div>
          <div className={styles.anaSayfa}>Ana Sayfa</div>
          <a className={styles.profil} onClick={onProfilClick}>
            <img
              className={styles.profilChild}
              alt=""
              src="/group-209@2x.png"
            />
          </a>
          <img
            className={styles.groupChild9}
            alt=""
            src="/group-18205@2x.png"
          />
          <img
            className={styles.groupChild10}
            alt=""
            src="/group-18222@2x.png"
          />
        </section>
      </footer>
      <div className={styles.scrollGroup10}>
        <img
          className={styles.scrollGroup9Child}
          alt=""
          src="/group-18894@2x.png"
        />
        <img
          className={styles.scrollGroup9Item}
          alt=""
          src="/group-18893@2x.png"
        />
        <img
          className={styles.scrollGroup9Inner}
          alt=""
          src="/group-18895@2x.png"
        />
        <img
          className={styles.scrollGroup9Child1}
          alt=""
          src="/group-18897@2x.png"
        />
        <img
          className={styles.scrollGroup9Child2}
          alt=""
          src="/group-18894@2x.png"
        />
        <img
          className={styles.scrollGroup9Child3}
          alt=""
          src="/group-18896@2x.png"
        />
      </div>
      <div className={styles.scrollGroup11}>
        <img
          className={styles.scrollGroup9Child}
          alt=""
          src="/group-18894@2x.png"
        />
        <img
          className={styles.scrollGroup9Item}
          alt=""
          src="/group-18893@2x.png"
        />
        <img
          className={styles.scrollGroup9Inner}
          alt=""
          src="/group-18895@2x.png"
        />
        <img
          className={styles.scrollGroup9Child1}
          alt=""
          src="/group-18897@2x.png"
        />
        <img
          className={styles.scrollGroup9Child2}
          alt=""
          src="/group-18894@2x.png"
        />
        <img
          className={styles.scrollGroup9Child3}
          alt=""
          src="/group-18896@2x.png"
        />
      </div>
      <div className={styles.scrollGroup12}>
        <img
          className={styles.scrollGroup9Child}
          alt=""
          src="/group-18894@2x.png"
        />
        <img
          className={styles.scrollGroup9Item}
          alt=""
          src="/group-18893@2x.png"
        />
        <img
          className={styles.scrollGroup9Inner}
          alt=""
          src="/group-18895@2x.png"
        />
        <img
          className={styles.scrollGroup9Child1}
          alt=""
          src="/group-18897@2x.png"
        />
        <img
          className={styles.scrollGroup9Child2}
          alt=""
          src="/group-18894@2x.png"
        />
        <img
          className={styles.scrollGroup9Child3}
          alt=""
          src="/group-18896@2x.png"
        />
      </div>
    </div>
  );
};

export default AnaPanelMobil;
