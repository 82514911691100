import { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./ProfilMobil.module.css";

const ProfilMobil = () => {
  const navigate = useNavigate();
  useEffect(() => {
    const scrollAnimElements = document.querySelectorAll(
      "[data-animate-on-scroll]"
    );
    const observer = new IntersectionObserver(
      (entries) => {
        for (const entry of entries) {
          if (entry.isIntersecting || entry.intersectionRatio > 0) {
            const targetElement = entry.target;
            targetElement.classList.add(styles.animate);
            observer.unobserve(targetElement);
          }
        }
      },
      {
        threshold: 0.15,
      }
    );

    for (let i = 0; i < scrollAnimElements.length; i++) {
      observer.observe(scrollAnimElements[i]);
    }

    return () => {
      for (let i = 0; i < scrollAnimElements.length; i++) {
        observer.unobserve(scrollAnimElements[i]);
      }
    };
  }, []);

  const onGroupLinkClick = useCallback(() => {
    navigate("/ana-panel-mobil");
  }, [navigate]);

  return (
    <div className={styles.profilMobil}>
      <div className={styles.profilMobilChild} />
      <div className={styles.mehmetAliSrmeliParent} data-animate-on-scroll>
        <div className={styles.mehmetAliSrmeli}>Mehmet Ali Sürmeli</div>
        <div className={styles.snf}>3.Sınıf</div>
        <div className={styles.groupChild} />
        <img className={styles.groupItem} alt="" src="/group-34.svg" />
      </div>
      <div className={styles.proflm} data-animate-on-scroll>
        PROFİLİM
      </div>
      <div className={styles.profilMobilItem} data-animate-on-scroll />
      <div className={styles.statistiklerWrapper} data-animate-on-scroll>
        <div className={styles.statistikler}>İstatistikler</div>
      </div>
      <div className={styles.rozetlerimWrapper} data-animate-on-scroll>
        <div className={styles.rozetlerim}>Rozetlerim</div>
      </div>
      <div className={styles.puanVeYorumlarm}>Puan ve Yorumlarım</div>
      <div className={styles.kitaplmWrapper} data-animate-on-scroll>
        <div className={styles.kitaplm}>Kitaplığım</div>
      </div>
      <div className={styles.arkadalarWrapper} data-animate-on-scroll>
        <div className={styles.arkadalar}>Arkadaşlar</div>
      </div>
      <div className={styles.toplamOkumaSresi}>Toplam Okuma Süresi</div>
      <div className={styles.ortalamaOkumaHz}>Ortalama Okuma Hızı</div>
      <div className={styles.enOkOkuduun}>En Çok Okuduğun Türler</div>
      <div className={styles.saat21Dakika}>325 Saat, 21 Dakika, 15 Saniye</div>
      <div className={styles.dakikada80Kelime}>1 Dakikada 80 Kelime</div>
      <div className={styles.macera}>Macera</div>
      <div className={styles.yk}>Öykü</div>
      <div className={styles.bilimKurgu}>Bilim Kurgu</div>
      <div className={styles.masal}>Masal</div>
      <div className={styles.gezi}>Gezi</div>
      <div className={styles.kitap}>20 Kitap</div>
      <div className={styles.kitap1}>15 Kitap</div>
      <div className={styles.kitap2}>12 Kitap</div>
      <div className={styles.kitap3}>10 Kitap</div>
      <div className={styles.kitap4}>8 Kitap</div>
      <img className={styles.profilMobilInner} alt="" src="/group-13856.svg" />
      <div className={styles.lineDiv} />
      <div className={styles.profilMobilChild1} />
      <div className={styles.profilMobilChild2} />
      <div className={styles.profilMobilChild3} />
      <div className={styles.profilMobilChild4} />
      <div className={styles.profilMobilChild5} />
      <div className={styles.profilMobilChild6} />
      <div className={styles.profilMobilChild7} />
      <img className={styles.groupIcon} alt="" src="/group-13859.svg" />
      <img className={styles.profilMobilChild8} alt="" src="/group-13862.svg" />
      <img className={styles.profilMobilChild9} alt="" src="/group-13865.svg" />
      <img
        className={styles.profilMobilChild10}
        alt=""
        src="/group-13867.svg"
      />
      <img
        className={styles.profilMobilChild11}
        alt=""
        src="/group-13870.svg"
      />
      <img
        className={styles.profilMobilChild12}
        alt=""
        src="/group-13873.svg"
      />
      <img
        className={styles.profilMobilChild13}
        alt=""
        src="/group-13876.svg"
      />
      <div className={styles.groupParent}>
        <div className={styles.enHzlKitapOkumaParent} data-animate-on-scroll>
          <div className={styles.enHzlKitapOkumaContainer}>
            <p className={styles.enHzl}>En Hızlı</p>
            <p className={styles.enHzl}>Kitap Okuma</p>
          </div>
          <img className={styles.groupInner} alt="" src="/group-13966.svg" />
        </div>
        <div className={styles.enOkKitapOkumaParent} data-animate-on-scroll>
          <div className={styles.enOkKitapOkumaContainer}>
            <p className={styles.enHzl}>En Çok</p>
            <p className={styles.enHzl}>Kitap Okuma</p>
          </div>
          <img className={styles.groupChild1} alt="" src="/group-13965.svg" />
        </div>
        <div
          className={styles.eitliTrlerdeKitapOkumaParent}
          data-animate-on-scroll
        >
          <div className={styles.enOkKitapOkumaContainer}>
            <p className={styles.enHzl}>Çeşitli Türlerde</p>
            <p className={styles.enHzl}>Kitap Okuma</p>
          </div>
          <img className={styles.groupChild2} alt="" src="/group-13967.svg" />
        </div>
      </div>
      <div className={styles.rectangleDiv} />
      <div className={styles.profilMobilChild14} />
      <div className={styles.profilMobilChild15} />
      <div className={styles.profilMobilChild16} />
      <div className={styles.profilMobilChild17} />
      <div className={styles.profilMobilChild18} />
      <div className={styles.profilMobilChild19} />
      <div className={styles.okumanyaSiralaman}>OKUMANYA SIRALAMAN</div>
      <div className={styles.mehmetKaya}>Mehmet Kaya</div>
      <div className={styles.nefiseTahsinolu}>Nefise Tahsinoğlu</div>
      <div className={styles.makbuleTrkolu}>Makbule Türkoğlu</div>
      <div className={styles.mehmetAliSrmeli1}>Mehmet Ali Sürmeli</div>
      <div className={styles.groupDiv}>
        <div className={styles.groupContainer}>
          <img className={styles.groupChild3} alt="" src="/group-13926.svg" />
          <div className={styles.div}>1</div>
        </div>
      </div>
      <div className={styles.profilMobilInner1}>
        <div className={styles.groupContainer}>
          <img className={styles.groupChild3} alt="" src="/group-13939.svg" />
          <div className={styles.div1}>2</div>
        </div>
      </div>
      <div className={styles.profilMobilInner2}>
        <div className={styles.groupContainer}>
          <img className={styles.groupChild3} alt="" src="/group-13946.svg" />
          <div className={styles.div2}>3</div>
        </div>
      </div>
      <div className={styles.profilMobilInner3}>
        <div className={styles.groupContainer}>
          <img className={styles.groupChild3} alt="" src="/group-13953.svg" />
          <div className={styles.div3}>45</div>
        </div>
      </div>
      <div className={styles.profilMobilChild20} />
      <div className={styles.puanParent}>
        <div className={styles.puan}>
          <p className={styles.enHzl}>3600</p>
          <p className={styles.enHzl}>Puan</p>
        </div>
        <img className={styles.groupChild7} alt="" src="/group-13956@2x.png" />
      </div>
      <div className={styles.puan2}>
        <p className={styles.enHzl}>2800</p>
        <p className={styles.enHzl}>Puan</p>
      </div>
      <div className={styles.puan4}>
        <p className={styles.enHzl}>2300</p>
        <p className={styles.enHzl}>Puan</p>
      </div>
      <div className={styles.puan6}>
        <p className={styles.enHzl}>1230</p>
        <p className={styles.enHzl}>Puan</p>
      </div>
      <img
        className={styles.profilMobilChild21}
        alt=""
        src="/group-13956@2x.png"
      />
      <img
        className={styles.profilMobilChild22}
        alt=""
        src="/group-13956@2x.png"
      />
      <img
        className={styles.profilMobilChild23}
        alt=""
        src="/group-13956@2x.png"
      />
      <div className={styles.scrollGroup8}>
        <div className={styles.image50Parent}>
          <img className={styles.image50Icon} alt="" src="/image-50@2x.png" />
          <div
            className={styles.loremIpsumDolor}
          >{`Lorem ipsum dolor sit amet, consectetur adipiscing elit. In auctor mi ut risus maximus volutpat. Aenean dapibus molestie diam id feugiat. `}</div>
          <div className={styles.puanm}>Puanım:</div>
          <div className={styles.asset1300x8Parent}>
            <img
              className={styles.asset1300x8Icon}
              alt=""
              src="/asset-1300x8@2x.png"
            />
            <img
              className={styles.asset1300x8Icon1}
              alt=""
              src="/asset-1300x8@2x.png"
            />
            <img
              className={styles.asset1300x8Icon2}
              alt=""
              src="/asset-1300x8@2x.png"
            />
            <img
              className={styles.asset3300x8Icon}
              alt=""
              src="/asset-3300x8@2x.png"
            />
            <img
              className={styles.asset3300x8Icon1}
              alt=""
              src="/asset-3300x8@2x.png"
            />
          </div>
        </div>
        <div className={styles.loremIpsumDolorSitAmetCoParent}>
          <div
            className={styles.loremIpsumDolor1}
          >{`Lorem ipsum dolor sit amet, consectetur adipiscing elit. In auctor mi ut risus maximus volutpat. Aenean dapibus molestie diam id feugiat. `}</div>
          <div className={styles.puanm1}>Puanım:</div>
          <div className={styles.asset1300x8Group}>
            <img
              className={styles.asset1300x8Icon3}
              alt=""
              src="/asset-1300x8@2x.png"
            />
            <img
              className={styles.asset1300x8Icon4}
              alt=""
              src="/asset-1300x8@2x.png"
            />
            <img
              className={styles.asset1300x8Icon5}
              alt=""
              src="/asset-1300x8@2x.png"
            />
            <img
              className={styles.asset3300x8Icon2}
              alt=""
              src="/asset-3300x8@2x.png"
            />
            <img
              className={styles.asset3300x8Icon3}
              alt=""
              src="/asset-3300x8@2x.png"
            />
          </div>
          <img className={styles.image48Icon} alt="" src="/image-48@2x.png" />
        </div>
        <div className={styles.image50Group}>
          <img className={styles.image48Icon} alt="" src="/image-50@2x.png" />
          <div
            className={styles.loremIpsumDolor1}
          >{`Lorem ipsum dolor sit amet, consectetur adipiscing elit. In auctor mi ut risus maximus volutpat. Aenean dapibus molestie diam id feugiat. `}</div>
          <div className={styles.puanm1}>Puanım:</div>
          <div className={styles.asset1300x8Group}>
            <img
              className={styles.asset1300x8Icon3}
              alt=""
              src="/asset-1300x8@2x.png"
            />
            <img
              className={styles.asset1300x8Icon4}
              alt=""
              src="/asset-1300x8@2x.png"
            />
            <img
              className={styles.asset1300x8Icon5}
              alt=""
              src="/asset-1300x8@2x.png"
            />
            <img
              className={styles.asset3300x8Icon2}
              alt=""
              src="/asset-3300x8@2x.png"
            />
            <img
              className={styles.asset3300x8Icon3}
              alt=""
              src="/asset-3300x8@2x.png"
            />
          </div>
        </div>
      </div>
      <div className={styles.servetKaralarParent}>
        <div className={styles.servetKaralar}>Servet Karalar</div>
        <div className={styles.groupWrapper}>
          <img className={styles.groupChild8} alt="" src="/group-341.svg" />
        </div>
      </div>
      <div className={styles.mehmetYaln}>Mehmet Yalçın</div>
      <div className={styles.profilMobilInner4}>
        <img className={styles.groupChild9} alt="" src="/group-18174.svg" />
      </div>
      <div className={styles.aliSrmeli}>Ali Sürmeli</div>
      <div className={styles.profilMobilInner5}>
        <img className={styles.groupChild10} alt="" src="/group-18176.svg" />
      </div>
      <div className={styles.hakkEtin}>Hakkı Çetin</div>
      <div className={styles.profilMobilInner6}>
        <img className={styles.groupChild8} alt="" src="/group-341.svg" />
      </div>
      <div className={styles.zhanKaygsz}>Özhan Kaygısız</div>
      <div className={styles.profilMobilInner7}>
        <img className={styles.groupChild8} alt="" src="/group-341.svg" />
      </div>
      <div className={styles.hamdiBaemez}>Hamdi Başeğmez</div>
      <div className={styles.profilMobilInner8}>
        <img className={styles.groupChild8} alt="" src="/group-341.svg" />
      </div>
      <div className={styles.cananOrhan}>Canan Orhan</div>
      <div className={styles.profilMobilInner9}>
        <img className={styles.groupChild14} alt="" src="/group-18169.svg" />
      </div>
      <div className={styles.ezginurTan}>Ezginur Tanış</div>
      <div className={styles.profilMobilInner10}>
        <img className={styles.groupChild15} alt="" src="/group-18171.svg" />
      </div>
      <div className={styles.merveMercan}>Merve Mercan</div>
      <div className={styles.profilMobilInner11}>
        <img className={styles.groupChild16} alt="" src="/group-18173.svg" />
      </div>
      <img
        className={styles.profilMobilChild24}
        alt=""
        src="/group-18184.svg"
      />
      <div className={styles.groupParent4}>
        <img className={styles.groupChild17} alt="" src="/group-18151@2x.png" />
        <img
          className={styles.image12Icon}
          alt=""
          src="/image-12@2x.png"
          data-animate-on-scroll
        />
        <img
          className={styles.image13Icon}
          alt=""
          src="/image-13@2x.png"
          data-animate-on-scroll
        />
        <img
          className={styles.image11Icon}
          alt=""
          src="/image-11@2x.png"
          data-animate-on-scroll
        />
        <img className={styles.groupChild18} alt="" src="/group-16838@2x.png" />
        <img
          className={styles.image49Icon}
          alt=""
          src="/image-49@2x.png"
          data-animate-on-scroll
        />
        <img className={styles.image50Icon2} alt="" src="/image-50@2x.png" />
        <img
          className={styles.image48Icon1}
          alt=""
          src="/image-48@2x.png"
          data-animate-on-scroll
        />
        <img
          className={styles.image12Icon1}
          alt=""
          src="/image-12@2x.png"
          data-animate-on-scroll
        />
        <img
          className={styles.image13Icon1}
          alt=""
          src="/image-13@2x.png"
          data-animate-on-scroll
        />
        <img
          className={styles.image11Icon1}
          alt=""
          src="/image-11@2x.png"
          data-animate-on-scroll
        />
        <img className={styles.groupChild19} alt="" src="/group-15665@2x.png" />
        <img
          className={styles.image49Icon1}
          alt=""
          src="/image-49@2x.png"
          data-animate-on-scroll
        />
        <img
          className={styles.image50Icon3}
          alt=""
          src="/image-50@2x.png"
          data-animate-on-scroll
        />
        <img
          className={styles.image48Icon2}
          alt=""
          src="/image-48@2x.png"
          data-animate-on-scroll
        />
        <img className={styles.groupChild20} alt="" src="/group-14712@2x.png" />
      </div>
      <img
        className={styles.profilMobilChild25}
        alt=""
        src="/group-18186@2x.png"
      />
      <img
        className={styles.profilMobilChild26}
        alt=""
        src="/group-18186@2x.png"
      />
      <img
        className={styles.profilMobilChild27}
        alt=""
        src="/group-18512.svg"
        data-animate-on-scroll
      />
      <div className={styles.path18815Parent}>
        <img className={styles.path18815Icon} alt="" src="/path-18812.svg" />
        <img className={styles.path18814Icon} alt="" src="/path-18814.svg" />
        <a className={styles.groupFrame} onClick={onGroupLinkClick}>
          <img className={styles.groupChild21} alt="" src="/group-82@2x.png" />
        </a>
        <div className={styles.profilim}>Profilim</div>
        <img className={styles.groupChild22} alt="" src="/group-18224@2x.png" />
        <img className={styles.groupChild23} alt="" src="/group-18205@2x.png" />
        <img className={styles.groupChild24} alt="" src="/group-18222@2x.png" />
      </div>
    </div>
  );
};

export default ProfilMobil;
